import React from 'react'

//import components
import Line from './Line'
import FullWidthImage from './FullWidthImage'
import Padding from './Padding'

//import styles
import styles from './NarrativesSection.module.css'

export default ({number, title, brief, full, half, supporting}) => (
    <div className={styles.narrativesSection}>
        <Padding />
        <div className={styles.description}>
            <h3>{number}</h3>
            <Line color={'black'}/>
            <h3>{title}</h3>
            <p>{brief}</p>
        </div>
        <Padding />
        <FullWidthImage src={full} alt={''} />
        <div className={styles.grid}>
            <div
                className={styles.half}
                style={{
                    backgroundImage: `url(${half})`
                }}
            >
                <img src={half} alt=''/>
            </div>
            
            <img src={supporting[0]} alt={''}/>
            <img src={supporting[1]} alt={''}/>
        </div>
    </div>
)